export default defineNuxtRouteMiddleware((to, from) => {
  console.log("checkcartMiddleware from", from.name);
  console.log("checkcartMiddleware to", to.name);

  // if (
  //   from.name === "cart-id" &&
  //   (to.name === "cart" || to.name === "index" || to.name === "login")
  // ) {
  //   const route = useRoute();
  //   const query = route.query;
  //   let path = "/cart";

  //   return navigateTo({ path, query });
  // }
  return true;
});
